<template>
  <modify-apply-block
    data-type="worker_job_title"
    ref="worker_job_title_p"
    :getEdit="getEdit"
    :saveEdit="saveEdit"
    :addEdit="addEdit"
    :form-parms-add="formParmsAdd"
    block-name="岗位信息"
    :getTitle="getTitle"
    :deal-data-info="dealDataInfo"
    ref-name="worker_job_title"
    :worker-id="workerId">
  </modify-apply-block>
</template>

<script>
import ModifyApplyBlock from './modifyApplyBlock'

import {
  workerJobTitleRequest
} from '@/api'

export default {
  components: {
    ModifyApplyBlock
  },
  data () {
    return {
      workerId: null
    }
  },
  created () {
    this.workerId = this.$router.history.current.query.workerId
    this.$store.dispatch('loadJobTitleList')
  },
  beforeRouteLeave (to, from, next) {
    if (this.$refs['worker_job_title_p'].openForm) {
      this.$refs['worker_job_title_p'].openForm = false
      next(false)
    } else {
      next()
    }
  },
  methods: {
    async getEdit () {
      if (this.workerId) {
        return await workerJobTitleRequest.getEdit({
          workerId: this.workerId
        })
      }
    },
    dealParm (parm) {
      if (typeof parm.jobTitleId === 'string') {
        parm.jobTitleId = this.$store.getters.jobTitleList.find(v => v.label === parm.jobTitleId).key
      }
      return parm
    },
    async saveEdit (parm) {
      return await workerJobTitleRequest.saveEdit(Object.assign({}, this.dealParm(parm), {
        dataId: parm.id,
        id: undefined
      }))
    },
    async addEdit (parm) {
      return await workerJobTitleRequest.addEdit(this.dealParm(parm))
    },
    dealDataInfo (data) {
      let jbData = this.$store.getters.jobTitleList.find(v => v.key === data.jobTitleId)
      if (jbData) {
        data.jobTitleType = jbData.data.type
        data.topLevel = jbData.data.topLevel
        data.mLevel = jbData.data.mLevel
        data.jobTitleTitle = jbData.data.title
      }
      return data
    },
    getTitle (data) {
      return (data.hisName || '') + ' ' + (data.jobTitleType || '') + ' ' + (data.hireStartDate ? data.hireStartDate.slice(0, 10) : '')
    }
  },
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          selectDatas: this.$store.getters.selectDatas['jobtitle_type_list'],
          label: '岗位专业',
          key: 'type',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          selectDatas: this.$store.getters.jobTitleList,
          label: '岗位',
          key: 'jobTitleId',
          check: {
            required: true
          },
          format: (data) => {
            return data.jobTitleType + ' ' + data.jobTitleTitle
          }
        },
        // {
        //   type: 'datePicker',
        //   label: '上岗时间',
        //   key: 'startDate',
        //   check: {
        //     required: true
        //   }
        // },
        // {
        //   type: 'datePicker',
        //   label: '离任时间',
        //   key: 'endDate'
        // },
        {
          type: 'datePicker',
          label: '聘任起始时间',
          key: 'hireStartDate',
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '聘任终止时间',
          key: 'hireEndDate'
        },
        {
          type: 'input',
          label: '在岗单位',
          key: 'hisName'
        }]
        return data
      }
    }
  }
}
</script>
